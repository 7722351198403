.form {
	margin-left: auto;
	margin-right: auto;
	border: 2px solid black;
	height: fit-content;
	width: 50vw;
}

.signup-title {
	margin: 20px;
}

ul {
	padding: 0px;
}

a {
	text-decoration: none;
}

.signup-container {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.input-form {
	background-color: #fffae3;
	border: 2px solid black;
	box-shadow: 5px 5px 5px gray;
	display: flex;
	flex-direction: column;
	height: 500px;
	justify-content: center;
	width: 80vw;

	margin-bottom: 80px;
}
.form-input,
.input-submit-button {
	display: block;
	margin: 10px 20px 10px 20px;
	padding-left: 10px;
	height: 60px;
	font-size: 1em;
}

.input-submit-button {
	border: 2px solid #009fb7;
	box-shadow: inset 0 0 0 0 #009fb7;
	color: #2a3e3c;
	cursor: pointer;

	font-weight: bold;
	letter-spacing: 1px;
	padding: 10px;
	text-align: center;
	transition: ease-out 0.4s;
	vertical-align: middle;
	width: 50%;
	margin: 20px auto 20px auto;
	-webkit-transition: ease-out 0.4s;
	-moz-transition: ease-out 0.4s;
}

.input-submit-button:hover {
	color: white;
	box-shadow: inset 800px 0 0 0 #009fb7;
}

.input-submit-button:disabled {
	color: gray;
	box-shadow: none;
}

.form-input::placeholder {
	font-size: 1.2em;
}

.login-title {
	text-align: center;
}

@media (min-width: 500px) {
	.input-form {
		width: 40vw;
	}
}
