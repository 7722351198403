@media (min-width: 800px) {
	.adopter-grid {
		display: grid;
		background-clip: content-box;
		padding: 5px;
		display: grid;
		grid-template-columns: 1fr 1.1fr;
		grid-template-rows: 1fr 1fr 1fr 1.2fr 0.5fr;
		grid-template-areas: "name adoption-date" "email phone" "address country" "comment comment" "adoption-submit-button adoption-submit-button";
	}

	.name {
		grid-area: name;
	}

	.adoption-date {
		grid-area: adoption-date;
	}

	.email {
		grid-area: email;
	}

	.phone {
		grid-area: phone;
	}

	.address {
		grid-area: address;
	}

	.country {
		grid-area: country;
	}

	.comment {
		grid-area: comment;
	}

	.adoption-submit-button {
		grid-area: adoption-submit-button;
		width: 20%;
		height: 40px;
		margin: 0px auto 0px auto;
	}

	.modal-inner {
		margin: 10px;
		width: 700px;
		padding: 10px;
	}
}

.react-datepicker-wrapper {
	display: block;
	width: 100%;
}

.adopt-comment-input {
	margin-left: 20px;
	padding-left: 4px;
	width: 95%;
	height: 60%;
}

.adopt-label {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.adopt-label,
.adopt-input {
	display: block;
	margin: 7px;
	text-align: left;
}

.adopt-input {
	margin-left: 20px;
	padding-left: 4px;
	height: 40px;
	width: 90%;
}

.adopt-input-style {
	border: 1px solid #cccccc;
	border-radius: 5px;
}

.adopt-error-style {
	padding-left: 20px;
}

.center-error {
	text-align: center;
}
