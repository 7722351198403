.block {
	display: block;
	font-size: 1em;
	height: 40px;
	padding-left: 10px;
	margin-bottom: 10px;
	width: 100%;
}

.bold {
	color: #f7567c;
	font-size: 0.8em;
	font-weight: bold;
}

.fielditem {
	height: 80px;
}

.shot-date-input {
	display: block;
	text-align: center;
	font-size: 0.8em;
	height: 30px;
	margin: 10px auto 10px auto;
	width: 80%;
}

.react-datepicker-wrapper {
	width: 50%;
	display: block;
}

.loader-container {
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;
	bottom: 50vh;
}

.img-preview {
	width: 250px;
}

.loader {
	border: 16px solid gray;
	border-radius: 50%;
	opacity: 0.5;
	border-top: 16px solid #ff7300;
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

fieldset {
	display: flex;
	margin: 10px 0px 10px 0px;
	flex-direction: column;
}
