.form {
	margin-left: auto;
	margin-right: auto;
	border: 2px solid black;
	height: fit-content;
	width: 50vw;
}

ul {
	padding: 0px;
}

a {
	text-decoration: none;
}

.login-container {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	padding-top: 20vh;
}

.input-form {
	background-color: #fffae3;
	border: 2px solid black;
	box-shadow: 5px 5px 5px gray;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 80vw;
	height: 500px;
	margin-bottom: 80px;
}

.form-input {
	display: block;
	margin: 10px 20px 10px 20px;
	height: 10vh;
	padding-left: 10px;
	font-size: 1em;
}

.form-input::placeholder {
	font-size: 1.2em;
}

.login-loader-container {
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;
	bottom: 50%;
}

.login-title {
	padding-top: 1vh;
	text-align: center;
}

.demo-info {
	margin: 0px;
}

@media (min-width: 500px) {
	.input-form {
		width: 40vw;
	}
}
