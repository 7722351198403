@import url("https://fonts.googleapis.com/css?family=Candal|Khula|Montserrat&display=swap");

* {
	box-sizing: border-box;
	font-family: Montserrat, sans-serif;
	color: #2a2929;
}

body {
	font-size: 20px;
	text-align: center;
	line-height: 1.5;
	margin: 0 0 0 0;
}

::-webkit-scrollbar {
	width: 20px;
}

::-webkit-scrollbar-thumb {
	background: lightgray;
	border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
	background: rgb(54, 56, 58);
}

.error {
	font-size: 10px;
	font-style: italic;
	color: red;
}

.dogslist {
	min-height: 800px;
}

.form-container {
	font-size: 1em;
	padding-top: 30px;
	text-align: left;
	width: 80vw;
	margin: 0 auto 0 auto;
	padding: 20px;
	background-color: #f0eff1;
	box-shadow: 5px 5px 5px 5px gray;
	border: 2px solid black;
}

.form-title {
	text-align: center;
}

textarea {
	width: 100%;
	height: 10vh;
}

.form-buttons {
	text-align: center;
	justify-content: center;
	display: flex;
	flex-wrap: wrap;
}

.button-style,
.add-new-dog,
.cancel,
.submit,
.see-notes,
.go-back,
.edit,
.delete,
.notes-button {
	border: 2px solid #009fb7;
	box-shadow: inset 0 0 0 0 #009fb7;
	color: #2a2929;
	cursor: pointer;
	display: inline-block;
	font-size: 1em;
	font-weight: bold;
	letter-spacing: 1px;
	padding: 10px;
	text-align: center;
	transition: ease-out 0.4s;
	/* width: 30vw; */
	margin: 10px 5px 10px 5px;
	vertical-align: middle;
	-webkit-transition: ease-out 0.4s;
	-moz-transition: ease-out 0.4s;
}

.delete a,
.delete a:hover {
	color: #f7567c;
	-webkit-text-stroke: 0.1px black;
}

.add-new-dog:hover,
.go-back:hover,
.edit:hover,
.cancel:hover,
.submit:hover,
.notes-button:hover,
.see-notes:hover,
.delete:hover {
	box-shadow: inset 800px 0 0 0 #009fb7;
	color: white;
	text-shadow: none;
}

.dog-link:hover {
	color: white;
	text-shadow: none;
}

.add-new-dog:disabled,
.go-back:disabled,
.edit:disabled,
.cancel:disabled,
.submit:disabled {
	color: gray;
	box-shadow: none;
}

ul {
	padding: 0px;
	list-style: none;
}

li {
	list-style: none;
}

a {
	text-decoration: none;
	font-weight: bold;
	font-size: 1em;
	display: block;
}

.image-field-item {
	display: flex;
	flex-direction: column;
}

.dog-img-link {
	display: inline;
}

.additional-notes,
.medical-notes {
	padding: 20px;
}

.updated-by {
	display: flex;
	justify-content: center;
	align-items: center;
}

main {
	padding-bottom: 5em;
}

.App {
	position: relative;
	min-height: 100%;
}

footer {
	width: 100%;
	height: 2.5em;
	bottom: 0;
}

@media (min-width: 500px) {
	.homepage,
	.dogslist {
		height: 100%;
	}

	.form-container {
		max-width: 400px;
	}
}

@media (min-width: 800px) {
	.updated-by {
		grid-area: updated-by;
		justify-content: space-evenly;
	}

	.form-buttons {
		flex-wrap: nowrap;
	}

	.nav-buttons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0px auto;
	}
}

@media (min-width: 800px) {
	.nav-buttons {
		max-width: 1000px;
	}
}
