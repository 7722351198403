@import url(https://fonts.googleapis.com/css?family=Candal|Khula|Montserrat&display=swap);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #fcfcfc;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.home-page-title {
	color: #fcfcfc;
	font-family: Candal, sans-serif;
	font-size: 3em;
	padding-top: 60px;
	margin: 0px;
	width: 100%;
	-webkit-text-stroke: 1px black;
}

.intro-details,
.header-title {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
}

.intro-details {
	padding: 20px;
}

.header-title {
	background-color: #009fb7;
}

.homepage {
	height: 100%;
}

.homepage-image {
	object-position: bottom 0px right 30px;
	width: 300px;
	height: 200px;
	object-fit: cover;
}

.options {
	margin-left: auto;
	margin-right: auto;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	text-align: center;
	vertical-align: middle;
}

.form-tab {
	border: 2px solid #011627;
	box-shadow: inset 0 0 0 0 #011627;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;
	height: 80px;
	letter-spacing: 1px;
	line-height: 60px;
	margin: 20px;
	padding: 10px;
	text-align: center;
	transition: ease-out 0.4s;
	vertical-align: middle;
	width: 80vw;
	-webkit-transition: ease-out 0.4s;
	-moz-transition: ease-out 0.4s;
}

.form-tab:hover {
	box-shadow: inset 800px 0 0 0 #011627;
}

.form-tab a:hover {
	color: #fff;
	text-shadow: none;
}

a:hover {
	text-shadow: 2px 3px #ff7300;
}

.input-login-button {
	border: 2px solid black;
	width: 40vh;
	margin: 10px auto;
	font-size: 1em;
}

.input-login-button:hover {
	cursor: pointer;
}

@media (min-width: 700px) {
	.form-tab {
		width: 400px;
	}

	.intro-details {
		display: -webkit-flex;
		display: flex;
	}

	.options {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
	}

	.header-title {
		-webkit-flex-direction: row;
		        flex-direction: row;
		padding-top: 20px;
	}

	.home-page-title {
		font-size: em;
		left: 5vw;
		padding-top: 0;
		position: relative;
		width: 40vw;
	}

	.homepage-image {
		height: 250px;
		right: -30px;
		width: 350px;
		object-position: bottom 0px right 30px;
		position: relative;
	}
}

@media (min-width: 1200px) {
	.header-title {
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		padding-top: 20px;
	}

	.homepage-image {
		left: 0;
		width: 400px;
	}

	.home-page-title {
		font-size: 4em;
		width: 40%;
	}
}

.block {
	display: block;
	font-size: 1em;
	height: 40px;
	padding-left: 10px;
	margin-bottom: 10px;
	width: 100%;
}

.bold {
	color: #f7567c;
	font-size: 0.8em;
	font-weight: bold;
}

.fielditem {
	height: 80px;
}

.shot-date-input {
	display: block;
	text-align: center;
	font-size: 0.8em;
	height: 30px;
	margin: 10px auto 10px auto;
	width: 80%;
}

.react-datepicker-wrapper {
	width: 50%;
	display: block;
}

.loader-container {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	position: relative;
	bottom: 50vh;
}

.img-preview {
	width: 250px;
}

.loader {
	border: 16px solid gray;
	border-radius: 50%;
	opacity: 0.5;
	border-top: 16px solid #ff7300;
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

fieldset {
	display: -webkit-flex;
	display: flex;
	margin: 10px 0px 10px 0px;
	-webkit-flex-direction: column;
	        flex-direction: column;
}


@media (min-width: 800px) {
	.adopter-grid {
		display: grid;
		background-clip: content-box;
		padding: 5px;
		display: grid;
		grid-template-columns: 1fr 1.1fr;
		grid-template-rows: 1fr 1fr 1fr 1.2fr 0.5fr;
		grid-template-areas: "name adoption-date" "email phone" "address country" "comment comment" "adoption-submit-button adoption-submit-button";
	}

	.name {
		grid-area: name;
	}

	.adoption-date {
		grid-area: adoption-date;
	}

	.email {
		grid-area: email;
	}

	.phone {
		grid-area: phone;
	}

	.address {
		grid-area: address;
	}

	.country {
		grid-area: country;
	}

	.comment {
		grid-area: comment;
	}

	.adoption-submit-button {
		grid-area: adoption-submit-button;
		width: 20%;
		height: 40px;
		margin: 0px auto 0px auto;
	}

	.modal-inner {
		margin: 10px;
		width: 700px;
		padding: 10px;
	}
}

.react-datepicker-wrapper {
	display: block;
	width: 100%;
}

.adopt-comment-input {
	margin-left: 20px;
	padding-left: 4px;
	width: 95%;
	height: 60%;
}

.adopt-label {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
}

.adopt-label,
.adopt-input {
	display: block;
	margin: 7px;
	text-align: left;
}

.adopt-input {
	margin-left: 20px;
	padding-left: 4px;
	height: 40px;
	width: 90%;
}

.adopt-input-style {
	border: 1px solid #cccccc;
	border-radius: 5px;
}

.adopt-error-style {
	padding-left: 20px;
}

.center-error {
	text-align: center;
}

.image-upload-preview {
	height: 250px;
}

.form {
	margin-left: auto;
	margin-right: auto;
	border: 2px solid black;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	width: 50vw;
}

ul {
	padding: 0px;
}

a {
	text-decoration: none;
}

.login-container {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	height: 100%;
	-webkit-justify-content: center;
	        justify-content: center;
	padding-top: 20vh;
}

.input-form {
	background-color: #fffae3;
	border: 2px solid black;
	box-shadow: 5px 5px 5px gray;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 80vw;
	height: 500px;
	margin-bottom: 80px;
}

.form-input {
	display: block;
	margin: 10px 20px 10px 20px;
	height: 10vh;
	padding-left: 10px;
	font-size: 1em;
}

.form-input::-webkit-input-placeholder {
	font-size: 1.2em;
}

.form-input:-ms-input-placeholder {
	font-size: 1.2em;
}

.form-input::placeholder {
	font-size: 1.2em;
}

.login-loader-container {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	position: relative;
	bottom: 50%;
}

.login-title {
	padding-top: 1vh;
	text-align: center;
}

.demo-info {
	margin: 0px;
}

@media (min-width: 500px) {
	.input-form {
		width: 40vw;
	}
}

.form {
	margin-left: auto;
	margin-right: auto;
	border: 2px solid black;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	width: 50vw;
}

.signup-title {
	margin: 20px;
}

ul {
	padding: 0px;
}

a {
	text-decoration: none;
}

.signup-container {
	height: 100vh;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.input-form {
	background-color: #fffae3;
	border: 2px solid black;
	box-shadow: 5px 5px 5px gray;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	height: 500px;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 80vw;

	margin-bottom: 80px;
}
.form-input,
.input-submit-button {
	display: block;
	margin: 10px 20px 10px 20px;
	padding-left: 10px;
	height: 60px;
	font-size: 1em;
}

.input-submit-button {
	border: 2px solid #009fb7;
	box-shadow: inset 0 0 0 0 #009fb7;
	color: #2a3e3c;
	cursor: pointer;

	font-weight: bold;
	letter-spacing: 1px;
	padding: 10px;
	text-align: center;
	transition: ease-out 0.4s;
	vertical-align: middle;
	width: 50%;
	margin: 20px auto 20px auto;
	-webkit-transition: ease-out 0.4s;
	-moz-transition: ease-out 0.4s;
}

.input-submit-button:hover {
	color: white;
	box-shadow: inset 800px 0 0 0 #009fb7;
}

.input-submit-button:disabled {
	color: gray;
	box-shadow: none;
}

.form-input::-webkit-input-placeholder {
	font-size: 1.2em;
}

.form-input:-ms-input-placeholder {
	font-size: 1.2em;
}

.form-input::placeholder {
	font-size: 1.2em;
}

.login-title {
	text-align: center;
}

@media (min-width: 500px) {
	.input-form {
		width: 40vw;
	}
}

* {
	box-sizing: border-box;
	font-family: Montserrat, sans-serif;
	color: #2a2929;
}

body {
	font-size: 20px;
	text-align: center;
	line-height: 1.5;
	margin: 0 0 0 0;
}

::-webkit-scrollbar {
	width: 20px;
}

::-webkit-scrollbar-thumb {
	background: lightgray;
	border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
	background: rgb(54, 56, 58);
}

.error {
	font-size: 10px;
	font-style: italic;
	color: red;
}

.dogslist {
	min-height: 800px;
}

.form-container {
	font-size: 1em;
	padding-top: 30px;
	text-align: left;
	width: 80vw;
	margin: 0 auto 0 auto;
	padding: 20px;
	background-color: #f0eff1;
	box-shadow: 5px 5px 5px 5px gray;
	border: 2px solid black;
}

.form-title {
	text-align: center;
}

textarea {
	width: 100%;
	height: 10vh;
}

.form-buttons {
	text-align: center;
	-webkit-justify-content: center;
	        justify-content: center;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.button-style,
.add-new-dog,
.cancel,
.submit,
.see-notes,
.go-back,
.edit,
.delete,
.notes-button {
	border: 2px solid #009fb7;
	box-shadow: inset 0 0 0 0 #009fb7;
	color: #2a2929;
	cursor: pointer;
	display: inline-block;
	font-size: 1em;
	font-weight: bold;
	letter-spacing: 1px;
	padding: 10px;
	text-align: center;
	transition: ease-out 0.4s;
	/* width: 30vw; */
	margin: 10px 5px 10px 5px;
	vertical-align: middle;
	-webkit-transition: ease-out 0.4s;
	-moz-transition: ease-out 0.4s;
}

.delete a,
.delete a:hover {
	color: #f7567c;
	-webkit-text-stroke: 0.1px black;
}

.add-new-dog:hover,
.go-back:hover,
.edit:hover,
.cancel:hover,
.submit:hover,
.notes-button:hover,
.see-notes:hover,
.delete:hover {
	box-shadow: inset 800px 0 0 0 #009fb7;
	color: white;
	text-shadow: none;
}

.dog-link:hover {
	color: white;
	text-shadow: none;
}

.add-new-dog:disabled,
.go-back:disabled,
.edit:disabled,
.cancel:disabled,
.submit:disabled {
	color: gray;
	box-shadow: none;
}

ul {
	padding: 0px;
	list-style: none;
}

li {
	list-style: none;
}

a {
	text-decoration: none;
	font-weight: bold;
	font-size: 1em;
	display: block;
}

.image-field-item {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.dog-img-link {
	display: inline;
}

.additional-notes,
.medical-notes {
	padding: 20px;
}

.updated-by {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

main {
	padding-bottom: 5em;
}

.App {
	position: relative;
	min-height: 100%;
}

footer {
	width: 100%;
	height: 2.5em;
	bottom: 0;
}

@media (min-width: 500px) {
	.homepage,
	.dogslist {
		height: 100%;
	}

	.form-container {
		max-width: 400px;
	}
}

@media (min-width: 800px) {
	.updated-by {
		grid-area: updated-by;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
	}

	.form-buttons {
		-webkit-flex-wrap: nowrap;
		        flex-wrap: nowrap;
	}

	.nav-buttons {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: center;
		        justify-content: center;
		margin: 0px auto;
	}
}

@media (min-width: 800px) {
	.nav-buttons {
		max-width: 1000px;
	}
}

